import {Button, Box, Typography, Container} from '@mui/material'
import { useLocalStorage } from '@uidotdev/usehooks';
import { Subtitle, Title } from '../components/base/typography';
import { ExperimentProgress, Pages, baseExperimentStatus } from '../Types';

export default function Debriefing() {

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    const handleClickConfirm = () => {
        setExperiment({ ...experiment, page: Pages.POST_QUESTIONNAIRE })
    };

    return (
        <Container>

            <Title>
                Die Studie ist vorbei!
            </Title>

            <Subtitle>
                {experiment.status === ExperimentProgress.OVER && "Sie haben das Rollenspiel abgeschlossen."}
                {experiment.status === ExperimentProgress.LEFT_EARLY && "Sie haben das Rollenspiel vorzeitig verlassen."}
                {experiment.status === ExperimentProgress.TIMEOUT && "Sie haben das Zeitlimit erreicht."}
            </Subtitle>

            <Typography paddingBottom={2}>
                Vielen Dank für Ihre Teilnahme in unserer Studie über E-Mail-Clients!
            </Typography>

            <Typography paddingBottom={2}>
                Wie Sie vielleicht bemerkt haben, waren einige E-Mails, die {employee.name} erhalten hatte, sogenannte <b>Phishing-Mails</b>. Dabei handelt es sich um Betrugsversuche, in denen Kriminelle sich als andere Personen oder Organisationen ausgeben, um Informationen wie Passwörter oder persönliche Daten zu stehlen.
            </Typography>

            <Typography paddingBottom={6}>
                Unsere Studie misst die Effektivität von verschiedenen Warnungsmechanismen, die Benutzern die URLs von aufgerufenen Webseiten vor der Weiterleitung erneut zeigt. Diese Mechanismen sollen die Wahrscheinlichkeit senken, dass Benutzer*innen ungewollt eine falsche Webseite besuchen.
                Ihre Teilnahme hilft uns, die Sicherheit von E-Mails zu verbessern und das Phishing-Risiko zu senken.
            </Typography>

            <Typography paddingBottom={2}>
                <b>Wir würden Ihnen gerne auf der nächsten Seite ein paar letzte Fragen stellen. Im Anschluss erhalten Sie direkt den Teilnahme-Code für Ihre Vergütung.</b>
            </Typography>

            <Box py={2}></Box>
            <Button variant="contained" className="Button" onClick={handleClickConfirm}>
                Weiter
            </Button>

        </Container>
    );
}