import { Box, Typography } from "@mui/material";
import TargetURL from "../elements/TargetURL";
import PlainURL from "../elements/PlainURL";

export default function ImpersonationFailure({answer, target, ...props}) {

    return (
        <Box>
            <Typography>Ihre Antwort war:</Typography>
            <PlainURL url={answer} />
            <br />

            <Typography>Der Link führt Sie aber auf die folgende Webseite:</Typography>
            <PlainURL url={target.domainTLD} />
            <br />

            <Typography>Bitte beachten Sie das {target.domainTLD} nicht zu {answer} gehört!</Typography>
            <br />

            <Typography>Sind Sie sicher, dass der Link Sie zur erwarteten Webseite führt? Falls ja, fahren Sie fort. Andernfalls können Sie zurückgehen und die E-Mail erneut lesen oder sie der IT melden.</Typography>
        </Box>
    )
}