import {useState} from 'react';
import {Button, Box, Typography, Container, Paper} from '@mui/material'
import FormControl from '@mui/material/FormControl';
import { BACKEND_ADDRESS } from '../App';
import { Subtitle, Title } from '../components/base/typography';
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import QuestionnaireLikert from '../components/framework/Questionnaire/QuestionnaireLikert';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Pages } from '../Types';


let commonQs = [
    ["detecting_spam_by_reading", "Ich bin der Meinung, dass ich die betrügerischen E-Mails beim Durchlesen erkannt habe."],
    ["scam_difficult_to_detect", "Ich hatte Schwierigkeiten, die betrügerischen E-Mails zu erkennen."],
    ["recognize_legitimate_urls", "Ich fand es einfach, die richtigen URLs als solche zu erkennen."],
    ["scam_spotting_difficulty", "Ich hatte Schwierigkeiten, die betrügerischen URLs in der E-Mail zu erkennen."]
];

let passiveQs = [
    ["re-reading_useful", "Das erneute Lesen der angeklickten URLs im Bestätigungsdialog war hilfreich."],
    ["ignored_urls", "Ich habe die URLs im Bestätigungsdialog ignoriert."],
    ["changed_mind_at_confirmation", "Die URLs im Bestätigungsdialog zu sehen, hat mir bei meiner Entscheidung geholfen."]
];

let reorderQs = [
    ["re-reading_useful", "TODO Die Elemente der angeklickten URL neu anordnen zu müssen, war hilfreich. //Having to reorder the clicked URLs on the confirmation page was useful."],
    ["ignored_urls", "TODO Ich habe die URLs nicht gelesen, während ich sie angeordnet habe. //I did not read the URLs while reordering them"],
    ["changed_mind_at_confirmation", "Das Anordnen der URL-Elemente hat mir bei meiner Entscheidung geholfen. // Reordering the URLs on the challenge page helped me decide."]
];

let mechanismQs = [
    ["help_spot_phishing", "Die Sicherheitschecks haben mir dabei geholfen, Phishing-URLs zu erkennen."],
    ["spot_phishing_without_tool", "Ich habe die Sicherheitschecks nicht gebraucht, um die Phishing-URLs zu erkennen."],
    ["clearly_highlighted_mistakes", "Der Sicherheitscheck hat Fehler, die ich beim Lesen der URLs gemacht hatte, klar hervorgehoben."],
    ["tool_in_the_way", "Die Sicherheitschecks haben mich bei meiner Arbeit behindert."],
    ["tutorial_clear", "Die Einführung zum Rollenspiel war verständlich."],
    ["tutorial_sufficient_information", "Die Einführung zum Rollenspiel hat alle benötigen Information enthalten."],
    ["coloring_url_useful", "Die farbliche Markierung der verschiedenen URL-Teile war hilfreich."],
    ["wish_reading_url_simpler", "Ich hätte mir gewünscht, dass die URL einfacher lesbar gewesen wäre."],
    ["tool_presentation_confusing", "Die Darstellung der Sicherheitschecks war verwirrend."],
]

let specificQs_list = [
    ["click_domain_challenge_useful", "Die Sicherheitscheck, in dem ich die Domain aus einer Liste auswählen musste, war hilfreich."],
    ["click_domain_challenge_frustrating", "Die Sicherheitscheck, in dem ich die Domain aus einer Liste auswählen musste, war nervig."],
    ["click_domain_challenge_difficult", "Die Sicherheitscheck, in dem ich die Domain aus einer Liste auswählen musste, war schwierig."]
]
let specificQs_highlight = [
    ["highlight_domain_challenge_useful", "Die Sicherheitscheck, in dem ich die Domain markieren musste, war hilfreich."],
    ["highlight_domain_challenge_frustrating", "Die Sicherheitscheck, in dem ich die Domain markieren musste, war nervig."],
    ["highlight_domain_challenge_difficult", "Die Sicherheitscheck, in dem ich die Domain markieren musste, war schwierig."],
]
let specificQs_type = [
    ["type_domain_challenge_useful", "Die Sicherheitscheck, in dem ich die Domain abtippen musste, war hilfreich."],
    ["type_domain_challenge_frustrating", "Die Sicherheitscheck, in dem ich die Domain abtippen musste, war nervig."],
    ["type_domain_challenge_difficult", "Die Sicherheitscheck, in dem ich die Domain abtippen musste, war schwierig."],
]


export default function PostQ() {

    const [feedbackFormValues, setFeedbackFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleChange = (event) => {
        
        setFeedbackFormValues({
            ...feedbackFormValues,
            [event.target.name]: event.target.value,
          });
          
    };

    const handleClickConfirm = () => {
        
        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'feedback',
                    'form_data': feedbackFormValues
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.REWARD })
            })
            .catch((err) => console.log(err));
  
        
    };

    function renderQs(questionsList) {

        return (
            <QuestionnaireGroup least="Stimme gar nicht zu" most="Stimme voll und ganz zu">
                {
                    questionsList.map((items, idx) => (
                        <QuestionnaireLikert key={idx} label={items[1]} name={items[0]} onChange={handleChange} />
                    ))
                }
            </QuestionnaireGroup>
        )
    }

    return (
        <Container>
            <Title>
                Abschlussfragebogen
            </Title>

        <Typography>
            Wir werden Ihnen nun ein paar abschliessende Fragen zum Rollenspiel stellen. Bitte füllen Sie den folgende Umfrage aus, um die Studie abzuschliessen.
        </Typography>
        <Typography pb={4} pt={2}>
            Antworten Sie mit einem Wert zwischen 1 ("Ich stimme gar nicht zu") und 5 ("Ich stimme voll und ganz zu").
        </Typography>

        <FormControl>
            {renderQs(commonQs)}
        </FormControl>

        {participant.group === "baseline" &&
            <>
                <Subtitle>URL-Bestätigungsseite</Subtitle>
                <Typography>
                    Die folgenden Fragen beziehen sich auf die Seite, auf der Sie die URL der Webseite <b>erneut lesen und bestätigen mussten</b>. Zur Erinnerung sehen Sie unten ein Beispiel.</Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/baseline.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(passiveQs)}
                </FormControl>
            </>
        }

        {participant.group === "reorder" &&
            <>
                <Subtitle>Domain-Teile anordnen</Subtitle>
                <Typography>
                    TODO Die folgenden Fragen beziehen sich auf den Sicherheitscheck, bei dem Sie <b>Teile der Domain auf die Linie ziehen mussten</b>. Zur Erinnerung sehen Sie unten ein Beispiel.
                    </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/reorder.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(reorderQs)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.length &&
            <>
                <Subtitle>Aufgaben zu den Links</Subtitle>
                <Typography>
                    Die folgenden allgemeinen Fragen betreffen die Sicherheitschecks, die sie durchführen mussten, wenn Sie auf eine URL geklickt haben.
                </Typography>
                <FormControl>
                    {renderQs(mechanismQs)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("1") &&
            <>
                <Subtitle>Domain aus Liste auswählen</Subtitle>
                <Typography mb={4}>
                    Die folgenden Fragen betreffen den Sicherheitscheck, in der Sie <b>die Domain der Webseite aus einer Liste auswählen</b> mussten. Zur Erinnerung sehen Sie unten ein Beispiel.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/list.gif' width="75%" style={{ margin: "1rem auto" }} />
                    </Paper>
                    {renderQs(specificQs_list)}
                </FormControl></>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("2") &&
            <>
                <Subtitle>Domain markieren</Subtitle>
                <Typography mb={4}>
                Die folgenden Fragen betreffen den Sicherheitscheck, in der Sie <b>die Domain mit der Maus markieren</b> mussten. Zur Erinnerung sehen Sie unten ein Beispiel.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/highlight.gif' width="75%" style={{margin: "1rem auto"}}/>
                    </Paper>
                    {renderQs(specificQs_highlight)}
                </FormControl>
            </>
        }

        {participant.group === "treatment" && experiment.sawChallenges.includes("3") &&
            <>
                <Subtitle>Domain abtippen</Subtitle>
                <Typography mb={4}>
                    Die folgenden Fragen betreffen den Sicherheitscheck, in der Sie <b>die Domain abtippen</b> mussten. Zur Erinnerung sehen Sie unten ein Beispiel.
                </Typography>
                <FormControl>
                    <Paper elevation={6}>
                        <img src='images/type.gif' width="75%" style={{margin: "1rem auto"}}/>
                    </Paper>
                    {renderQs(specificQs_type)}
                </FormControl>
            </>
        }

        <Box py={2}></Box> 
        <Button variant="contained" className="Button" onClick={(event) => handleClickConfirm()}>
            Weiter
        </Button>
        <p>&nbsp;</p>
        
        </Container>
    );
}
