import { Typography, Container, List, ListItem, Paper } from '@mui/material'
import { Subtitle, Title } from '../components/base/typography';
import { useLocalStorage } from '@uidotdev/usehooks';
import { BACKEND_ADDRESS } from '../App';

import Consent from '../components/framework/Consent';
import { baseExperimentStatus, Pages } from '../Types';

export default function ConsentShort() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);

    const handleClickConfirm = (event) => {

        fetch(BACKEND_ADDRESS + "/users", {
            method: 'POST', headers: { 'Content-type': 'application/json' }, body: JSON.stringify({
                "id": participant.platformId ? participant.platformId : "NOID",
            })
        })
            .then(async (res) => {
                let res_json = await res.json();
                setParticipant(res_json);
                setExperiment({ ...experiment, page: Pages.PRE_QUESTIONNAIRE })
            })
            .catch((err) => console.log(err));

    };

    return (
        <Container>

            <Title>
                Studie zur Benutzerfreundlichkeit von E-Mail-Clients
            </Title>

            <Typography pb={2}>
                Wir sind ein Forschungsteam der <a href='https://ethz.ch'>ETH Zürich</a>, einer öffentlichen Universität in der Schweiz. Wir untersuchen, wie Menschen mit E-Mail-Clients interagieren und möchten Sie fragen, ob Sie bereit wären, an unserem Projekt zur Benutzerfreundlichkeit einer neuartigen Benutzeroberfläche teilzunehmen.
            </Typography>

            <Typography pb={2}>
                Sie übernehmen die Rolle einer fiktiven Person, die in einem Unternehmen arbeitet. Sie erhalten Zugriff auf das geschäftliche E-Mail-Postfach dieser Person und werden gebeten, in einer festgelegten Zeit so viele E-Mails wie möglich abzuarbeiten.
                Wir bereiten Sie mit einer kurzen Einführung auf Ihre Aufgabe vor.
            </Typography>

            <Typography pb={6}>
                Wir werden Ihre Antworten auf die Fragebögen sowie Ihre Leistung während der Studie aufzeichnen. Es werden keine personenbezogene Daten über Sie erfasst und außer aggregierten Statistiken werden keine Daten veröffentlicht. 
                &nbsp;<b>Sie finden das vollständige Einverständnisformular <a href='./consent-full' target='_blank'>hier</a>.</b>
            </Typography>

            <Typography>
                Für Fragen oder Rückmeldungen können Sie das Forschungsteam per E-Mail kontaktieren: Daniele Lain, ETH Zürich (<a href='mailto:daniele.lain@inf.ethz.ch'>daniele.lain@inf.ethz.ch</a>).
            </Typography>
            <Typography>
                Der Datenschutzbeauftragte der ETH Zürich ist Tomislav Mitar (<a href='mailto:tomislav.mitar@sl.ethz.ch'>tomislav.mitar@sl.ethz.ch</a>)
            </Typography>
            <Typography pb={2}>
                Dieses Projekt wurde von der Ethikkommission der ETH Zürich unter der Nummer EK-2023-N-204 bewilligt.
            </Typography>
            <Typography pb={4}>
            Das Sekretariat der Ethikkommission der ETH Zürich steht Ihnen bei Beschwerden im Zusammenhang mit Ihrer Teilnahme zur Verfügung. Kontakt: <a href='mailto:ethics@sl.ethz.ch'>ethics@sl.ethz.ch</a> oder <a href='tel:0041446328572'>+41 44 632 85 72</a>.
            </Typography>



            <Paper elevation={3} sx={{ padding: "1em 4em 2em", backgroundColor: "#F8F8F8" }}>
                <Subtitle>
                    Einverständniserklärung
                </Subtitle>

                <Typography paddingBottom={2}>
                    Ich, als Teilnehmer*in, bestätige hiermit, dass ich:
                </Typography>

                <List dense>
                    <ListItem key={1}>
                        • &nbsp;<a href='./consent-full' target='_blank'>die vollständigen Informationen über die Studie</a>&nbsp; gelesen und verstanden habe.
                    </ListItem>
                    <ListItem key={3}>
                        • ausreichend Zeit hatte, um über meine Teilnahme zu entscheiden.
                    </ListItem>
                    <ListItem key={4}>
                        • freiwillig an dieser Studie teilnehme und zustimme, dass meine Daten wie oben beschrieben verwendet werden dürfen.
                    </ListItem>
                    <ListItem key={5}>
                        • mir bewusst bin, dass ich meine Teilnahme jederzeit beenden kann.
                    </ListItem>
                </List>

                <Consent consentHandler={handleClickConfirm} />
            </Paper>
        </Container>
    );
}