import { Box, Typography } from "@mui/material";
import TargetURL from "../elements/TargetURL";
import { useMemo } from "react";
import { diff } from "./tmp.js";
import PlainURL from "../elements/PlainURL.js";
import { UrlFragment } from "../../base/typography.js";

export default function DifferenceFailure({answer, target, ...props}) {

    // TODO to be smart we should check if the answer ends by TLD and decide whether to show & compare the TLD or not

    const diff2 = useMemo(() => diff.diff(answer, target.domainTLD), [answer, target]);

    return (
        <Box>
            
            <Typography>Ihre Antwort war:</Typography>
            <PlainURL url={answer} />
            <br />

            <Typography>Der Link führt Sie aber auf die folgende Webseite:</Typography>
            <Typography>
            {
                diff2.changes.map((_d, index) => {
                    switch (_d.diff) {
                        case "=":
                            return <UrlFragment key={index}>{_d.char}</UrlFragment>
                        case "+":
                            return <UrlFragment key={index} style={{color: 'red'}}>{_d.char}</UrlFragment>
                        case "-":
                            return <UrlFragment key={index} style={{color: 'red', textDecoration: "line-through"}}>{_d.char}</UrlFragment>
                        default:
                            // diff is an object like {"replacedBy": "x"}
                            return <UrlFragment key={index} style={{color: 'red', textDecoration: "red wavy underline"}}>{_d.diff.replacedBy}</UrlFragment>
                    }
                })
            }
            </Typography>
            <br />

            <Typography>Sind Sie sicher, dass der Link Sie zur erwarteten Webseite führt? Falls ja, fahren Sie fort. Andernfalls können Sie zurückgehen und die E-Mail erneut lesen oder sie der IT melden.</Typography>
        </Box>
    )
}