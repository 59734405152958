import { useEffect, useState } from 'react';
import { Button, Box, Container, Typography, List, ListItem, ListItemText, Grid, Skeleton } from '@mui/material'
import { BACKEND_ADDRESS } from '../App';
import { Preformatted } from '../components/base/typography';
import EmployeeBadge from '../components/persona/EmployeeBadge';
import { useLocalStorage } from "@uidotdev/usehooks";
import { Title } from "../components/base/typography"
import { Pages } from '../Types';


export default function Background() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const [loadingEmployee, setLoadingEmployee] = useState(true);
    const [loadingCompany, setLoadingCompany] = useState(true);

    useEffect(() => {
        if (loadingEmployee) {
            fetch(BACKEND_ADDRESS + "/persona", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ '_id': participant._id })
            })
                .then(async (res) => {
                    let result = await res.json();
                    setEmployee(result);
                    setLoadingEmployee(false);
                })
                .catch((err) => console.log(err));
        }

        if (loadingCompany) {
            fetch(BACKEND_ADDRESS + "/company", {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ '_id': participant._id })
            })
                .then(async (res) => {
                    let result = await res.json();
                    setCompany(result);
                    setLoadingCompany(false);
                })
                .catch((err) => console.log(err));
        }

    }, []);


    const handleClickConfirm = () => {
        setExperiment({ ...experiment, page: Pages.MAILBOX_DEMO })
    };

    const handleProfilePicture = (picture) => {
        setEmployee({ ...employee, picture: picture });
    }

    return (
        <Container>
            <Grid container spacing={4}>

                <Grid item xs={8}>
                    <Title>
                        Ihre Identität
                    </Title>
                    {loadingEmployee || loadingCompany ? (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    ) : (
                        <>
                            <Typography>
                                In dieser Studie verkörpern Sie <b>{employee.name} {employee.surname}</b> und arbeiten bei <b>{company.name}</b>. Auf der rechten Seite sehen Sie Ihren Angestellten-Badge: Wenn Sie möchten, können Sie Ihr Aussehen Ihren Wünschen entsprechend anpassen.
                            </Typography>
                            <Typography paddingBottom={4}>
                                Sie sind zuständig für <b>{employee.responsibilities[0]} und {employee.responsibilities[1]}</b>.
                            </Typography>
                            <Typography>
                                Sie haben die folgenden Informationen über Ihren Arbeitgeber:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText>• Die Webseiten von {company.name} sind <Preformatted>{company.domains[0]}</Preformatted> and <Preformatted>{company.domains[1]}</Preformatted></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>• Ihre geschäftliche E-Mail-Adresse ist <Preformatted>{employee.email}</Preformatted></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>• Persönliche E-Mail-Adressen in der Firma folgen dem Schema <Preformatted>vorname.nachname@{company.domains[0]}</Preformatted>;
                                        einige Abteilungen können eigene E-Mail-Adressen haben (z.B., Human Resources nutzt <Preformatted>hr@{company.domains[0]}</Preformatted>)</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>• {company.name} nutzt einige externe Dienste für die Arbeit mit Dokumenten und zur Kommunikation: Google Drive (<Preformatted>drive.google.com</Preformatted>), Sharepoint (<Preformatted>sharepoint.com</Preformatted>), Microsoft Teams (<Preformatted>teams.microsoft.com</Preformatted>)</ListItemText>
                                </ListItem>
                            </List>

                            <Typography>
                                <b>Ihre Aufgabe ist es, das E-Mail-Postfach von {employee.name} zu verwalten</b>: Sie werden eine begrenzte Zeit dafür haben und sollen so viele E-Mails wie möglich abarbeiten.
                                Wir geben Ihnen eine kurze Einführung, die Ihnen zeigt, wie Sie Ihre Aufgaben lösen.
                            </Typography>

                            <Button variant="contained" className="Button" onClick={handleClickConfirm} sx={{ marginTop: '2rem' }}>
                                Weiter
                            </Button>
                        </>
                    )
                    }
                </Grid>

                <Grid item xs={4}>
                    {loadingEmployee || loadingCompany ? (
                        <Skeleton variant="circular" width={200} height={200} />
                    ) : (
                        <EmployeeBadge
                            employee={employee}
                            company={company}
                            pictureSelector={true}
                            pictureSelectorHandler={handleProfilePicture}
                        />
                    )
                    }
                </Grid>

            </Grid>
        </Container>
    );
}