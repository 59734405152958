import { useState } from 'react';
import { Button, Box, Typography, Container, Grid, Item } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { BACKEND_ADDRESS } from '../App';
import QuestionnaireRadio from '../components/framework/Questionnaire/QuestionnaireRadio';
import Likert from '../components/framework/Questionnaire/QuestionnaireLikert';
import QuestionnaireGender from '../components/framework/Questionnaire/QuestionnaireGender';
import { useLocalStorage } from "@uidotdev/usehooks";
import QuestionnaireGroup from '../components/framework/Questionnaire/QuestionnaireGroup';
import { Title } from '../components/base/typography';
import { Pages } from '../Types';


let known_services = {
    paypal: 0,
    stripe: 0,
    facebook: 0,
    instagram: 0,
    tiktok: 0,
    twitter: 0,
    ups: 0,
    dhl: 0,
    googleaccount: 0,
    icloud: 0
}

export default function PreQ() {

    const [formValues, setFormValues] = useState({});
    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", {});

    const handleClickConfirm = (event) => {

        fetch(BACKEND_ADDRESS + "/answers",
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    'participant_id': participant._id,
                    'form_name': 'onboarding',
                    'form_data': { ...formValues, known_services }
                })
            }
        )
            .then(async (res) => {
                let result = await res.json();
                setExperiment({ ...experiment, page: Pages.BACKGROUND });
            })
            .catch((err) => console.log(err));
    };

    const handleFormValueChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckboxChange = (event) => {

        if (known_services[event.target.value] == 1) {
            known_services[event.target.value] = 0;
        } else {
            known_services[event.target.value] = 1;
        }
    };

    return (
        <Container>
            <Title>
                Willkommen!
            </Title>

            <Typography>
                Vielen Dank, dass Sie an unserer Studie teilnehmen! <br />
                Zunächst möchten wir Sie um einige demografische Informationen bitten: <b>Bitte füllen Sie dieses Formular aus</b>, bevor Sie fortfahren.
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>

                <QuestionnaireGender />

                <QuestionnaireRadio
                    label="Alter"
                    values={[
                        { label: "18-20", value: "18-20" },
                        { label: "21-30", value: "21-30" },
                        { label: "31-40", value: "31-40" },
                        { label: "41-50", value: "41-50" },
                        { label: "51-60", value: "51-60" },
                        { label: "61+", value: "61+" }
                    ]}
                    name="age" />

                <QuestionnaireRadio
                    label="Höchster erreichter Bildungsabschluss"
                    values={[
                        { label: "Gymnasium / Berufslehre", value: "High school" },
                        { label: "BSc.", value: "Bsc." },
                        { label: "MSc.", value: "Msc." },
                        { label: "Doktortitel", value: "PhD" }
                    ]}
                    name="education" />

                <QuestionnaireRadio
                    label="Ich habe ein privates E-Mail-Konto"
                    values={[
                        { label: "Ja", value: "yes" },
                        { label: "Nein", value: "no" }
                    ]}
                    noAnswer={false}
                    name="personal_email" />

                <QuestionnaireRadio
                    label="Ich habe ein geschäftliches E-Mail-Konto"
                    values={[
                        { label: "Ja", value: "yes" },
                        { label: "Nein", value: "no" }
                    ]}
                    noAnswer={false}
                    name="work_email" />
            </QuestionnaireGroup>
            
            <br />
            <Typography>
                Im Folgenden wählen Sie bitte einen Wert zwischen 1 und 5, wobei 1 für "sehr selten" oder "nie" und 5 für "andauernd" oder "immer" steht. <br />
                <b>Wie oft am Tag nutzen Sie...</b>
            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>
                <Likert
                    label="einen Desktop- / Laptop-Computer"
                    name="computer_use" />

                <Likert
                    label="ein Smartphone"
                    name="smartphone_use" />

                <Likert
                    label="E-Mail"
                    name="email_use" />

                <Likert
                    label="Messenger-Apps (z.B. SMS, WhatsApp, Signal, iMessage, ...)"
                    name="messaging_use" />

                <Likert
                    label="andere Kommunikationsprogramme (z.B. Teams, Slack, Zoom, Webex, ...)"
                    name="job_communication_use" />

                <Likert
                    label="Computer für nicht-technische Aufgaben (z.B. Microsoft Office, Datenerfassung, ...)"
                    name="job_computer_use" />

                <Likert
                    label="Computer für technische Aufgaben (z.B. Programmierung, IT-Tätigkeiten, ...)"
                    name="job_technical_computer_use" />


            </QuestionnaireGroup>
            
            <br />
            <Typography>
            Abschließend möchten wir Sie zu Ihrem <b>Wissen über Cybersicherheit</b> und Ihren bisherigen Erfahrungen mit Online-Betrug befragen.            </Typography>

            <QuestionnaireGroup onChange={handleFormValueChange}>
                <QuestionnaireRadio
                    label="Ich bin mit dem Thema E-Mail-Betrug vertraut"
                    values={[
                        { label: "1 (Nicht vertraut)", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5 (Expert*in)", value: "5" }
                    ]}
                    noAnswer={true}
                    name="scam_familiarity" />

                <QuestionnaireRadio
                    label="Ich bin mit dem Begriff Phishing vertraut"
                    values={[
                        { label: "1 (Nicht vertraut)", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5 (Expert*in)", value: "5" }
                    ]}
                    noAnswer={true}
                    name="phishing_familiarity" />

                <QuestionnaireRadio
                    label="Ich habe in den letzten sechs Monaten mindestens eine Betrugsemail an meine private E-Mailadresse erhalten."
                    values={[
                        { label: "Nein", value: "no" },
                        { label: "Eine", value: "one" },
                        { label: "Mehr als eine", value: "more than one" },
                        { label: "Weiss ich nicht mehr", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="received_phishing_personal" />

                <QuestionnaireRadio
                    label="Ich bin im letzten Jahr auf eine Betrugsemail hereingefallen."
                    values={[
                        { label: "Nein", value: "no" },
                        { label: "Nein, aber fast", value: "almost" },
                        { label: "Ja", value: "yes" },
                        { label: "Weiss ich nicht mehr", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="fell_for_scam" />

                <QuestionnaireRadio
                    label="Ich habe in den letzten drei Monaten mindestens eine Betrugsemail an meine geschäftliche E-Mail-Adresse erhalten."
                    values={[
                        { label: "Nein", value: "no" },
                        { label: "Eine", value: "one" },
                        { label: "Mehr als eine", value: "more than one" },
                        { label: "Ich erinnere mich nicht", value: "don't recall" }
                    ]}
                    noAnswer={true}
                    name="received_phishing_work" />

                <QuestionnaireRadio
                    label="Ich werde in meinem Beruf regelmässig im Bereich E-Mail-Sicherheit unterrichtet."
                    values={[
                        { label: "Nein", value: "no" },
                        { label: "Nur einmal oder selten", value: "sporadically" },
                        { label: "Ja", value: "yes" },
                        { label: "Ich verwende keine E-Mails in meinem Job", value: "don't use emails" }
                    ]}
                    noAnswer={true}
                    name="job_training" />
            </QuestionnaireGroup>
            
            <br />
            <Typography>
                <b>Welche der folgenden Online-Dienste haben Sie bereits verwendet?</b> Sie können so viele auswählen, wie sie möchten.
            </Typography>

            <FormLabel id="familiar-services-checkbox" sx={{ paddingTop: '20px', lineHeight: '2.4rem' }}>Ich bin vertraut mit...</FormLabel>
            <FormGroup onChange={handleCheckboxChange} aria-labelledby="familiar-services-checkbox" name="known_services" row>
                <FormControlLabel control={<Checkbox />} label="Facebook" value="facebook" />
                <FormControlLabel control={<Checkbox />} label="Instagram" value="instagram" />
                <FormControlLabel control={<Checkbox />} label="X (Twitter)" value="twitter" />
                <FormControlLabel control={<Checkbox />} label="UPS" value="ups" />
                <FormControlLabel control={<Checkbox />} label="DHL" value="dhl" />
                <FormControlLabel control={<Checkbox />} label="FedEx" value="fedex" />
                <FormControlLabel control={<Checkbox />} label="Google Drive" value="googledrive" />
                <FormControlLabel control={<Checkbox />} label="Onedrive / Sharepoint" value="onedrive" />
                <FormControlLabel control={<Checkbox />} label="Microsoft Teams" value="teams" />
                <FormControlLabel control={<Checkbox />} label="Slack" value="slack" />
                <FormControlLabel control={<Checkbox />} label="PayPal" value="paypal" />
                <FormControlLabel control={<Checkbox />} label="Stripe" value="stripe" />
            </FormGroup>
            <Box py={2}></Box>

            <Box py={2}></Box>
            <Button variant="contained" className="Button" onClick={handleClickConfirm}>
                Weiter
            </Button>
            <p>&nbsp;</p>


        </Container>
    );
}