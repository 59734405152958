import React, { useState } from 'react';
import { FormControlLabel, FormLabel, TextField, Radio, RadioGroup } from "@mui/material"

export default function QuestionnaireGender({onChange, ...props}) {

  let handleChange = onChange ? onChange : () => undefined;
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'bottom';

  return (
    <div className='questionnaire-row'>
      <FormLabel id="gender-radio-button">Gender</FormLabel>
      <RadioGroup onChange={handleChange} row aria-labelledby="gender-radio-button" name="gender">
        <FormControlLabel labelPlacement={labelPlacement} value='female' label='Weiblich' control={<Radio />} />
        <FormControlLabel labelPlacement={labelPlacement} value='male' label='Männlich' control={<Radio />} />
        <FormControlLabel labelPlacement={labelPlacement} label='Freie Anwort' control={<TextField name="gender" onChange={handleChange} size='small' variant='standard' margin='dense'/>} />
        <FormControlLabel labelPlacement={labelPlacement} value='no answer' label='Keine Angabe' control={<Radio />} />

      </RadioGroup>
    </div>
  );
}
