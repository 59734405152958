import { Button, Grid, Box, Typography, Container, List, ListItem, ListItemText, Drawer, Paper, IconButton, Collapse } from '@mui/material';
import EmailViewer from '../components/mailbox/EmailViewer';
import Timer from '../components/framework/Timer';
import { useState, useEffect, useMemo } from 'react';
import { BACKEND_ADDRESS } from '../App';
import { ChallengeTypes, ExperimentProgress, baseExperimentStatus, MechanismStatus, Pages, FailureTypes, TutorialStatus } from '../Types';
import { logEvent } from '../networking';
import ProfilePicture from '../components/persona/ProfilePicture';
import MailboxButton from '../components/mailbox/MailboxButton';
import {Phishing, MarkEmailUnread, Archive, Help, NoEncryption, Title, ChevronRight, ChevronLeft, CheckBox, Work} from '@mui/icons-material';
import EmailsList from '../components/mailbox/EmailsList';
import MailboxButtons from '../components/mailbox/MailboxButtons'
import SelectEmail from '../components/mailbox/SelectEmail';
import LoggerDialog from '../components/dialogs/LoggerDialog';
import { useLocalStorage } from '@uidotdev/usehooks';
import MailboxHeader from '../components/mailbox/MailboxHeader';
import { defaultTourOptions, nextButton, backButton, doneButton, studyProtocol, tutorialMailList } from './Tutorials';
import { Preformatted } from '../components/base/typography';
import TaskProgress from '../components/framework/TaskProgress';
import Shepherd from 'shepherd.js';
  



export default function MailClientTutorial() {

    const [participant, setParticipant] = useLocalStorage("participant", {});

    // const [mailList, setMailList] = useLocalStorage("emails", []);
    // const [mailCount, setMailCount] = useLocalStorage("mailCount", 0);
    // const [openEmail, setOpenEmail] = useLocalStorage("openEmail", '');

    const [mailList, setMailList] = useState(tutorialMailList);
    const [mailCount, setMailCount] = useState(tutorialMailList.length);
    const [openEmail, setOpenEmail] = useState(tutorialMailList[0]);

    const [leaveDialogOpen, setleaveDialogOpen] = useState(false);
    const [roleplayDialogOpen, setroleplayDialogOpen] = useState(false);
    const [handledDialogOpen, setHandledDialogOpen] = useState(false);
    const [reportedDialogOpen, setReportedDialogOpen] = useState(false);
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [protocolOpen, setProtocolOpen] = useState(true);
    const [showProtocol, setShowProtocol] = useState(false);
    const [postMechanism, setPostMechanism] = useState(false);

    const [employee, setEmployee] = useLocalStorage("employee", {});
    const [company, setCompany] = useLocalStorage("company", {});

    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);


    const tour = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.COMPLETED_FIRST });
            console.log("Tutorial 1 completed");
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            {
                text: `Sie werden nun Schritt für Schritt durch die Studie geführt und werden sehen, wie Sie mit Jordan's Postfach interagieren können.`,
                buttons: [ nextButton ],
            },
            {
              text: `Dies ist die erste E-Mail, die Jordan heute erhalten hat.
              <b>Sie enthält keinen Link</b>. Diese Art von E-Mail müssen Sie lediglich lesen, bevor Sie auf den Button "Als erledigt markieren" klicken.`,
              attachTo: { element: '.mailbox-viewer-container', on: 'left' },
              buttons: [ nextButton ],
            },
            {
                text: `Dies ist Jordan's zweite E-Mail. <b>Sie enthält einen Link</b>: um diese Art von E-Mail zu verarbeiten, müssen Sie auf den Link klicken, damit Jordan die entsprechende Webseite besuchen kann.
                In diesem Beispiel muß Jordan die neusten Zahlen zu FuturaCom's Facebook-Seite überprüfen.
                <br /><br />
                Beachten Sie, dass dies ein Rollenspiel ist. <b>Ein Klick auf einen Link führt NICHT zum Verlassen der Studienseite.</b> Jordan wird sich ohne Ihre Hilfe um anfallende Aufgaben kümmern.
                `,
                attachTo: { element: '.mailbox-viewer-container', on: 'left' },
                buttons: [ {
                    text: "Weiter",
                    action() {
                      return this.complete();
                    },
                  } ],
                when: { 
                    "before-show": () => { 
                        handleListClick(null, mailList[1]);
                    }
                },
            },
        ];
        tour.addSteps(mailclientSteps);
        return tour;
    }, [experiment.page]);

    const tour2 = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.on('complete', () => {
            setTutorialOpen(false);
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.COMPLETED, page: Pages.MAILBOX });
        });
        tour.on('start', () => {
            setTutorialOpen(true);
        });

        const mailclientSteps = [
            { 
                text: `Manchmal erhalten wir <b>unerwünschte oder gar bösartige E-Mails</b>, die uns täuschen wollen. Möglicherweise haben Sie selbst schon solche E-Mails bekommen. Das passiert leider auch Jordan.`,
                buttons: [ nextButton ],
                when : { 
                    "before-show": () => {
                        handleListClick(null, mailList[2]);
                    }
                }
            },
            {
                text: `Dies ist Jordan's dritte E-Mail. <b>Diese E-Mail ist verdächtig</b>: Es handelt sich um einen Phishing-Versuch, in dem sich jemand als Angestellter des Tech-Supports ausgibt und nach Jordans Passwort fragt. 
                <br /> 
                Falls Sie auf eine verdächtige E-Mail treffen, klicken Sie nicht auf den Link. Melden Sie stattdessen die E-Mail bei der IT, indem Sie auf den Button "Verdächtige E-Mail melden" klicken.`,
                attachTo: { element: (() => '.mailbox-viewer-container'), on: 'left' },
                buttons: [ nextButton ],
            },
            {
                text: `Sie sind am Ende der Einführung angelangt: Sie werden nun Jordans E-Mails unter Zeitdruck lesen. Sie haben maximal 15 Minuten, um so viele E-Mails wie möglich abzuarbeiten.
                <br /><br />
                Auf der rechten Seite können Sie die Instruktionen ein- und ausblenden. Des Weiteren können Sie mit einem Klick auf Ihr Profilbild die relevanten Informationen zu Jordan anzeigen lassen.`,
                attachTo: { element: '.mailbox-protocol-container', on: 'left' },
                buttons: [ doneButton ],
                when: {
                    "before-show": () => {
                        setShowProtocol(true);
                    },
                }

            }
        ];
        tour.addSteps(mailclientSteps);
        return tour;
    }, [experiment.page]);

    let listeners = [];

    useEffect(() => {
        document.getElementById("mailbox-viewer").scrollTo(0, 0);
      }, [openEmail]);

    const roleplayDescription = <></>

    const handleListClick = (event, email) => {
        setMailList(mailList.map((mail) => {
            if (mail._id === email._id) {
                mail.read = true;
                mail.selected = true;
            }
            else {
                mail.selected = false;
            }
            return mail;
        }));
        setOpenEmail(email);
    };

    function handleProcessedEmail() {}

    const handleLeave = () => {};

    const handleTimeout = () => {};

    const handleMarkUnread = () => {};

    const handleArchive = () => {};

    const handleSpamReport = () => {};

    const handleHandledEmailConfirm = () => {};

    const handleSpamReportConfirm = () => {};

    const handleRoleplayDialog = () => {};

    const displayTutorial = () => {};

    const displayProtocol = () => {};

    const showTour = () => {}

    // Tutorial states
    // 1. Tutorial not started (coming from BG) - start it
    if (experiment.tutorialStatus === TutorialStatus.NOT_STARTED) {
        logEvent(participant._id, "tutorial_start", {});
        handleListClick(null, mailList[0]);
        setExperiment({ ...experiment, tutorialStatus: TutorialStatus.STARTED_FIRST });
        tour.start();
    }
    // safeguard - if tutorial got closed we reopen it
    else if (experiment.tutorialStatus === TutorialStatus.STARTED_FIRST) {
        if (!tour.isActive()) {
            tour.start();
        }
    }   
    // 2. Done with first part - decide whether to show attention tutorial or not; set second tutorial to start
    else if (experiment.tutorialStatus === TutorialStatus.COMPLETED_FIRST) {
        if (participant.group !== "control") {
            let challengeId = null;
            let failureId = null;
            if (participant.group === "baseline") {
                challengeId = ChallengeTypes.PASSIVE_CONFIRM;
            }
            else if (participant.group === "reorder") {
                challengeId = ChallengeTypes.REALIGN_DOMAIN;
            }
            else {
                challengeId = ChallengeTypes.SELECT_DOMAIN;
                failureId = FailureTypes.SHOW;
            }
            setExperiment({ ...experiment, clickedUrl: mailList[1].url.url, challengeId: challengeId, failureId: failureId, page: Pages.ATTENTION_DEMO, tutorialStatus: TutorialStatus.SHOULD_START_SECOND });
        }
        else {
            setExperiment({ ...experiment, tutorialStatus: TutorialStatus.SHOULD_START_SECOND });
        }
    }
    // 3. Either we're back from the mechanism or we're coming from the first tutorial - start the second tutorial
    else if (experiment.tutorialStatus === TutorialStatus.SHOULD_START_SECOND) {
        setExperiment({ ...experiment, mechanism: MechanismStatus.NOT_FROM_MECHANISM, tutorialStatus: TutorialStatus.STARTED_SECOND });

        setTimeout( tour2.start(), 30);
    }
    // safeguard - if tutorial got closed we reopen it
    else if (experiment.tutorialStatus === TutorialStatus.STARTED_SECOND) {
        if (!tour2.isActive()) {
            setTimeout( () => tour2.start(), 30);
        }
    }

    return (
        <Box className="mailbox-container">
            <Box className="mailbox-header">
                <MailboxHeader name={company.name} logo={company.logo}>

                    <Button onClick={() => handleRoleplayDialog()} color='inherit' className={"profile-picture"}>
                        <ProfilePicture employee={employee} company={company} />
                    </Button>

                </MailboxHeader>
            </Box>
            <Box className="mailbox-buttons">
                <MailboxButtons>
                    <MailboxButton image={<MarkEmailUnread fontSize='large' />} callback={handleMarkUnread} disabled={!openEmail}>
                        Als ungelesen markieren
                    </MailboxButton>
                    <MailboxButton image={<CheckBox fontSize='large' />} callback={handleArchive} disabled={!openEmail || openEmail.url !== null} className={"mailbox-archive"}>
                        Als erledigt markieren
                    </MailboxButton>
                    <MailboxButton image={<Phishing fontSize='large' />} callback={handleSpamReport} disabled={!openEmail} className={"mailbox-tutorial-5"}>
                        Verdächte E-Mail melden
                    </MailboxButton>
                    <MailboxButton image={<Help fontSize='large' />} callback={displayProtocol} className={"mailbox-tutorial-7"}>
                        Hilfe
                    </MailboxButton>
                </MailboxButtons>
            </Box>
            <Box className="mailbox-body">
                <Box className="mailbox-list-container">
                    <Typography variant='h5' sx={{ px: "1rem" }}>
                        Inbox
                    </Typography>
                    <Box className="mailbox-list mailbox-tutorial-1" >
                        <EmailsList emails={mailList} clickHandler={handleListClick} />
                    </Box>
                </Box>
                <Box className="mailbox-viewer-container">
                    <Box className="mailbox-viewer" id="mailbox-viewer">
                    {openEmail ?
                        <EmailViewer email={openEmail} listeners={listeners} /> :
                        <SelectEmail />
                    }
                    </Box>
                </Box>
                <Box className="mailbox-protocol-container">
                    <Paper className='mailbox-protocol' elevation={3} sx={{width: "100%", height: "100%"}}>

                        <IconButton onClick={() => setProtocolOpen(!protocolOpen)}>
                        {protocolOpen ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    <Collapse in={protocolOpen} orientation='horizontal'>
                        { showProtocol ? studyProtocol: <></>}
                    </Collapse>
                    </Paper>
                </Box>
            </Box>
        </Box>

    );
}