import { Backdrop, Stack, Divider, Paper, Container, Grid, Avatar, Box, IconButton } from '@mui/material';
import Challenge from '../components/attention/Challenge';
import { useEffect, useMemo, useState } from 'react';
import Failure from '../components/attention/Failure';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Title } from '../components/base/typography';
import { ChallengeTypes, baseExperimentStatus, MechanismStatus, Pages } from '../Types';
import { HelpOutline, TourOutlined } from '@mui/icons-material';
import { CreateChallenge, CreateChallengeElement, CreateFailureElement } from '../components/attention/challenges';
import { pickChallenge } from '../utils';
import Shepherd from 'shepherd.js';

import 'shepherd.js/dist/css/shepherd.css';
import { attentionTutorialSteps, defaultTourOptions } from './Tutorials';
import { logEvent } from '../networking';


export default function Attention() {

    const [participant, setParticipant] = useLocalStorage("participant", {});
    const [company, setCompany] = useLocalStorage("company", {});
    const [experiment, setExperiment] = useLocalStorage("experimentStatus", baseExperimentStatus);
    const [openEmail, setOpenEmail] = useLocalStorage("openEmail", '');

    const [answer, setAnswer] = useState("");
    const [failed, setFailed] = useState(false);

    const [challengeId, setChallengeId] = useState(parseInt(experiment.challengeId));
    const [failureId, setFailureId] = useState(parseInt(experiment.failureId));
    const [challenge, setChallenge] = useState();

    useEffect(() => {
    async function _createChallenge() {
        let chal = await CreateChallenge(experiment.clickedUrl, challengeId);
        setChallenge(chal);
    }
    _createChallenge();
    }, [challengeId]);

    const challengeElement = useMemo(() => challenge ? CreateChallengeElement(challengeId, challenge, setAnswer) : null, [challengeId, challenge, setAnswer]);

    const failureElement = useMemo(() => failed ? CreateFailureElement(failureId, answer, challenge.url) : null, [failed]);



    const tour = useMemo(() => {
        let tour = new Shepherd.Tour(defaultTourOptions);
        tour.addSteps(attentionTutorialSteps(challengeId));
        return tour;
    }, [challengeId]);

    if (!experiment.mechanismOnboarded) {
        // tour.start();
        setExperiment({ ...experiment, mechanismOnboarded: true })
    }

    const proceedHandler = async () => {
        if (challenge.checkSolution(answer)) {
            continueHandler();
        } else {
            await logEvent(participant._id, "challenge_wrong", { "id": openEmail._id, "answer": answer});
            console.log(`${answer}, expected ${challenge.solution}`);
            setFailed(true);
        }
    }

    const continueHandler = async () => {
        await logEvent(participant._id, "challenge_continue", { "id": openEmail._id, "step": failed ? "failure_element" : "challenge_element"});
        setExperiment({ ...experiment, mechanism: MechanismStatus.VISITED, page: Pages.MAILBOX })
    }

    const goBackHandler = async () => {
        await logEvent(participant._id, "challenge_back", { "id": openEmail._id, "step": failed ? "failure_element" : "challenge_element" });
        setExperiment({ ...experiment, mechanism: MechanismStatus.ABANDONED, page: Pages.MAILBOX })
    }

    const reportHandler = async () => {
        await logEvent(participant._id, "challenge_report", { "id": openEmail._id, "step": failed ? "failure_element" : "challenge_element" });
        setExperiment({ ...experiment, mechanism: MechanismStatus.REPORTED, page: Pages.MAILBOX })
    }
    

    return (
        <>
        <Backdrop open={true}>
            <Container maxWidth="md">
                <Grid item xs={12} md={8}>
                    <Paper square={true} elevation={6} >

                        {/* Header */}
                        <Stack direction="row" spacing={2} p={1} alignItems="center">
                            <Avatar src={company.logo} sx={{ height: "50px", width: "50px", margin: "16px" }} />
                            <Box>
                                <Title gutterBottom>{company.name} Sicherheitscheck</Title>
                            </Box>
                            {!failed && <IconButton aria-label="Help" onClick={tour.start} className="attention-tutorial-help">
                                <HelpOutline />
                            </IconButton>}
                        </Stack>
                        <Divider variant='fullWidth' sx={{ marginBottom: "1rem" }} />

                        {/* Challenge */}
                        {!failed && challenge &&
                            <Challenge
                                challenge={challenge}
                                challengeElement={challengeElement}
                                solved={answer}
                                proceedHandler={proceedHandler}
                                goBackHandler={goBackHandler}
                                reportHandler={reportHandler}
                            />
                        }
                        {failed && challenge &&
                            <Failure
                                failureElement={failureElement}
                                proceedHandler={continueHandler}
                                goBackHandler={goBackHandler}
                                reportHandler={reportHandler}
                            />
                        }

                    </Paper>
                </Grid>
            </Container>
        </Backdrop>
        
        </>
    )
}