import { Container, Typography } from "@mui/material";
import { Subtitle, Title } from "../components/base/typography";

export default function ConsentFull() {

    return (
        <Container>
            <Title>
                Vollständiges Einverständnisformular - Studie zur Benutzerfreundlichkeit von E-Mail-Clients
            </Title>

            <Typography>
                Wir sind ein Forschungsteam der <a href='https://ethz.ch'>ETH Zurich</a>, einer öffentlichen Universität in der Schweiz. Wir untersuchen, wie Menschen mit E-Mail-Clients interagieren.
            </Typography>
            <Typography pb={2}>
                Wir möchten Sie fragen, ob Sie bereit wären, an unserem Forschungsprojekt teilzunehmen. Ihre Teilnahme ist freiwillig. Bitte lesen Sie den folgenden Text sorgfältig durch.
            </Typography>

            <Typography>
                Sie können uns kontaktieren, indem Sie eine E-Mail an Daniele Lain, ETH Zürich (<a href='mailto:daniele.lain@inf.ethz.ch'>daniele.lain@inf.ethz.ch</a>) schreiben.
            </Typography>
            <Typography>
                Der Datenschutzbeauftragte der ETH Zürich ist Tomislav Mitar (<a href='mailto:tomislav.mitar@sl.ethz.ch'>tomislav.mitar@sl.ethz.ch</a>).
            </Typography>

            <Subtitle>
                Was wird untersucht und wie?
            </Subtitle>
            <Typography>
                Wir haben die Benutzeroberflächen von E-Mail-Clients um neuartige Elemente erweitert, die die Benutzerfreundlichkeit von E-Mails verbessern sollen.
                Wir untersuchen den Effekt dieser Änderungen in einer Rollenspiel-Studie: Wir werden Ihnen eine fiktive Identität zuweisen und Sie bitten, die geschäftlichen E-Mails dieser Person in kurzer Zeit abzuarbeiten.

            </Typography>

            <Subtitle>
                Wer kann teilnehmen?
            </Subtitle>
            <Typography>
                Zur Teilnahme berechtigt sind alle Internetnutzenden ab 18 Jahren, die eine persönliche oder geschäftliche E-Mail-Adresse besitzen und regelmäßig verwenden.
                Die Teilnehmenden müssen fließend Englisch sprechen und auf der Plattform eine "Task Approval Rate" von mindestens 95 % haben.
            </Typography>

            <Subtitle>
                Was soll ich als Teilnehmer*in tun?
            </Subtitle>
            <Typography>
                Als Teilnehmer*in werden Sie zu Beginn der Studie gebeten, einen Fragebogen zu Ihren E-Mail-Gewohnheiten auszufüllen.
                Nach einer kurzen Einführung werden Sie die Rolle eines fiktiven Mitarbeiters oder einer fiktiven Mitarbeiterin eines Unternehmens übernehmen. Ihnen wird dann das Postfach dieser Person angezeigt. Sie haben eine festgelegte Zeit, um so viele E-Mails wie möglich gemäß den Anweisungen, die Ihnen gegeben werden, zu bearbeiten.
                Nachdem Sie das Postfach bearbeitet haben, werden Sie gebeten, einen zweiten Fragebogen zu Ihrer Erfahrung mit den von Ihnen getesteten Benutzeroberflächen auszufüllen.
            </Typography>

            <Subtitle>
                Was sind meine Rechte während meiner Teilnahme?
            </Subtitle>
            <Typography>
                Ihre Teilnahme an dieser Studie ist freiwillig. Sie können Ihre Teilnahme jederzeit ohne Angabe von Gründen und ohne Nachteile zurückziehen. Dazu klicken Sie bitte den deutlich gekennzeichneten Link.</Typography>

            <Subtitle>
                Welche Risiken und Vorteile kann ich erwarten?
            </Subtitle>
            <Typography>
                Wir sammeln keine persönlichen Daten während der Durchführung des Experiments.
                Ihre Daten sind sicher: Sie übernehmen die Rolle einer fiktiven Person und werden simulierte Geschäfts-E-Mails in einer geschützten Umgebung abarbeiten.
                Jede URL, auf die Sie während der Studie möglicherweise klicken, führt nicht zu gefährlichen Inhalten, sondern ausschließlich zu den Mechanismen, die wir testen.
            </Typography>

            <Subtitle>
                Erhalte ich eine Belohnung für meine Teilnahme?
            </Subtitle>
            Sie werden über die Studienplattform für Ihre Teilnahme bezahlt.
            Am Ende der Studie (oder wann immer Sie sich entscheiden, Ihre Teilnahme zu unterbrechen) erhalten Sie einen Einmalcode, der Ihre Teilnahme bestätigt; Sie werden gebeten, diesen Code auf der Website einzugeben, um Ihre Vergütung zu erhalten.            <Typography>

            </Typography>

            <Subtitle>
                Welche Daten werden von mir gesammelt und wie werden sie verwendet?
            </Subtitle>
            <Typography>
                Während der Studie werden keine personenbezogenen Daten von Ihnen erhoben.
                Einige Fragen in der Studie beziehen sich auf Eigenschaften, die als sensibel wahrgenommen werden könnten (z.B. Geschlecht, Altersgruppe). Alle Fragen in den Fragebögen sind optional; Teilnehmende können entscheiden, Fragen zu überspringen.            </Typography>
            <Typography>
                

                Während der Studie werden wir die folgenden Daten aus Ihrer Interaktion mit dem simulierten Postfach und den E-Mails erfassen:
                <ul>
                    <li>Leistung bei der Aufgabe (wie viele E-Mails abgearbeitet wurden, wie schnell und wie sie behandelt wurden).</li>
                    <li>Zeit pro Aufgabe: Wie lange es dauerte, jede E-Mail zu lesen und zu bearbeiten und wie viel Zeit Sie auf verschiedenen Studienseiten verbringen.</li>
                </ul>
            </Typography>
            <Typography>
                Alle gesammelten Daten werden sicher auf einem Server der System Security Group der ETH Zürich in pseudonymisierter Form mit Ihrer Plattform-ID gespeichert.
                Keine Daten werden roh veröffentlicht: Wir werden nach dieser Studie nur aggregierte Statistiken in wissenschaftlichen Arbeiten verwenden.
                Die aufgezeichneten Daten lassen es nicht zu, Schlussfolgerungen über einzelne Teilnehmende zu ziehen.
            </Typography>
            <Typography>
                Mitglieder der Ethikkommission der ETH Zürich können Zugang zu den Originaldaten zu Prüfungszwecken haben. Zu jeder Zeit wird strenge Vertraulichkeit gewahrt.
            </Typography>

            <Subtitle>
                Was sind meine Rechte bezüglich meiner persönlichen Daten?
            </Subtitle>
            <Typography>
                Vor der endgültigen Anonymisierung der gesammelten Daten können Sie jederzeit und ohne Angabe von Gründen Auskunft über die über Sie gesammelten personenbezogenen Daten verlangen. Sie können auch deren Berichtigung, Herausgabe, Sperrung oder Löschung beantragen. Bitte wenden Sie sich dazu an die oben angegebene Person.
            </Typography>

            <Subtitle>
                Wer hat diese Studie überprüft?
            </Subtitle>
            <Typography>
                Diese Studie wurde von der Ethikkommission der ETH Zürich unter dem Antrag EK-2023-N-204 geprüft und genehmigt.
            </Typography>

            <Subtitle>
                Bei Beschwerden
            </Subtitle>
            <Typography>
                Das Sekretariat des Ethikkomitees der ETH Zürich steht Ihnen zur Verfügung, um Ihnen bei Beschwerden im Zusammenhang mit Ihrer Teilnahme zu helfen.
            </Typography>
        </Container>
    );
}